/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CustomTitle, TeamDetailsStyledModal, UpdateTeamButton, AddMemberButton, CustomDropdownMenue, CustomDropdownMenuItem, TeamCardV2, MemberDataV2, CustomRadioButton, SelectImage, InputImage, CaptainLogo } from './styles';
import { Modal } from 'react-bootstrap';
import { checkImage, getOrientation, resetOrientation } from '../../utils/methods';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { fetchAddOrUpdateTeamDetails, fetchUsersToAddToTeam } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import LazyImage from '../common/LazyImage/LazyImage';

class TeamDetailsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleTeams:props.peopleTeams,
      addButtondisabled: true,
      radio: "upload",
      teamName: props.defaultTeamName,
      teamId: null,
      teamLogo: null,
      teamUsers: [],
      teamDetailsData: [],
      captainUID: null,
      teamIcons: [],
      imageUpdated: 0,
      default_icon: 1,
      defaultLogo: null, showModal: false
    };
    window.console.log("here are the props",props)
  }

  componentDidMount() {
    const { teamDetailsData, teamIcons, defaultTeamName } = this.props
    if (teamDetailsData[0]) this.setState({
      teamName: teamDetailsData[0].name != null ? teamDetailsData[0].name : defaultTeamName,
      teamUsers: teamDetailsData[0].team_users != null ? teamDetailsData[0].team_users : [],
      teamLogo: teamDetailsData[0].logo != null ? `${ImageUrl}/${teamDetailsData[0].logo}` : "",
      defaultLogo: teamDetailsData[0].logo != null ? teamDetailsData[0].logo : "",
      teamId: teamDetailsData[0].id != null ? teamDetailsData[0].id : null,
      teamDetailsData: teamDetailsData
    })
    if (teamIcons) {
      this.setState({ teamIcons: teamIcons })
    }
  }

  componentDidUpdate(prevProps) {
    const { teamDetailsData, teamIcons, defaultTeamName } = this.props
    if (this.state.teamIcons != this.props.teamIcons) {
      if (teamIcons) {
        this.setState({ teamIcons: teamIcons })
      }
    }

    if (this.state.teamDetailsData != this.props.teamDetailsData) {
      if (teamDetailsData[0]) this.setState({
        teamName: teamDetailsData[0].name != null ? teamDetailsData[0].name : defaultTeamName,
        teamUsers: teamDetailsData[0].team_users != null ? teamDetailsData[0].team_users : [],
        defaultLogo: teamDetailsData[0].logo != null ? teamDetailsData[0].logo : "",
        teamLogo: teamDetailsData[0].logo != null ? `${ImageUrl}/${teamDetailsData[0].logo}` : "",
        teamId: teamDetailsData[0].id != null ? teamDetailsData[0].id : null,
        teamDetailsData: teamDetailsData
      })
    }
  }

  onChangeImg = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          this.setState({
            teamLogo: reader.result,
            imageUpdated: 1,
            default_icon: 0
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                teamLogo: baseImage,
                imageUpdated: 1,
                default_icon: 0
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('profle-file').value = '';
      }
    } else {
      toast.error("image Error Message");
      document.getElementById('profle-file').value = '';
    }
  };

  handleAddModal = () => {
    const { hideModal, showAddModal, companyId, fetchUsersToAddToTeam, teamDetailsData } = this.props;
    const {teamId} = this.state;
    let obj = {};
    let teamUserCount=teamDetailsData[0].team_users.length;
    let teamCountLimit=teamDetailsData[0].count;
    obj['company_id'] = companyId;
    // hideModal();
    showAddModal(teamId, teamCountLimit, teamUserCount);
    fetchUsersToAddToTeam(obj);
  }

  handleMakeCaptain = (uid) => {
    const { teamUsers } = this.state;
    let allUsers = teamUsers;
    teamUsers.map((user, index) => {
      if (user.uid == uid) {
        if (allUsers[index].is_captain) {
          allUsers[index].is_captain = 0;
        }
        else { allUsers[index].is_captain = 1; }
      }
      else { allUsers[index].is_captain = 0; }
    })
    this.setState({
      teamUsers: allUsers
    })
  }

  submitTeamDetails = () => {
    const { teamId, teamName, teamLogo, defaultLogo, teamUsers, imageUpdated, default_icon } = this.state;
    const { fetchAddOrUpdateTeamDetails, hideModal, companyId, limit, pageNo } = this.props;
    let teamDetails = {};
    teamDetails['team_id'] = teamId;
    teamDetails['team_name'] = teamName;
    teamDetails['team_logo'] = default_icon ? defaultLogo : teamLogo;
    teamDetails['team_users'] = teamUsers;
    teamDetails['imageUpdated'] = imageUpdated;
    teamDetails['default_icon'] = default_icon;
    fetchAddOrUpdateTeamDetails(teamDetails, companyId, pageNo, limit, teamId);
    hideModal();
  }

  submitTeamDetailsForRemoveUsers = () => {
    const { teamId, teamName, teamLogo, defaultLogo, teamUsers, imageUpdated, default_icon } = this.state;
    const { fetchAddOrUpdateTeamDetails, companyId, limit, pageNo } = this.props;
    let teamDetails = {};
    teamDetails['team_id'] = teamId;
    teamDetails['team_name'] = teamName;
    teamDetails['team_logo'] = default_icon ? defaultLogo : teamLogo;
    teamDetails['team_users'] = teamUsers;
    teamDetails['imageUpdated'] = imageUpdated;
    teamDetails['default_icon'] = default_icon;
    fetchAddOrUpdateTeamDetails(teamDetails, companyId, pageNo, limit, teamId);
  }

  handleRemove = (uid) => {
    let updatedUsers = this.state.teamUsers.filter((obj) => obj.uid != uid);
    this.setState({
      teamUsers: updatedUsers
    }, () => {
      this.submitTeamDetailsForRemoveUsers();
    })
  }

  hideTeamPopup = () => {
    this.setState({
      radio: null
    }, () => {
      this.props.hideModal();
    })
  }

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  render() {
    const { showModal, hideModal, teamDetailsData, teamIcons, peopleTeams, /*maxTeamMemeberCount*/ } = this.props;
    const { teamName, teamUsers, teamLogo } = this.state;
    window.console.log("people teams",peopleTeams)
    return (
      <TeamDetailsStyledModal
        show={showModal}
        onHide={hideModal}
        dialogClassName="modal-90w"
        removeButton={teamLogo != null}
      >
        <Modal.Header peopleTeam={peopleTeams}>
          <CustomTitle>
            <div>
              <span>Team Details</span>
              <span className="closeImage" onClick={() => this.hideTeamPopup()}><img src='/public/images/new_close_icon.png' /></span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body>
          <div className="teamWrapper">
            <div className='teamNameDiv'>
              <div className="lable" style={{display:"flex", width:"100%",justifyContent:"space-between"}}>              
                <lable >Your Team Name<span>*</span></lable>
                <div><span>Max 50</span></div>
              </div>
              <input type="text" value={teamName} onChange={(e) => this.setState({ teamName: e.target.value })} maxLength={50}></input>
            </div>
            <div className='teamLogoDiv'>
              <h4>Your Team Logo</h4>
              <div className='radioButtonDiv'>
                <span><CustomRadioButton onClick={() => this.setState({ radio: this.state.radio == "upload" ? null : "upload" })}> {this.state.radio == "upload" && <div></div>} </CustomRadioButton> Upload</span>
                <span><CustomRadioButton onClick={() => this.setState({ radio: this.state.radio == "selected" ? null : "selected" })}> {this.state.radio == "selected" && <div></div>} </CustomRadioButton> Select from Below List</span>
              </div>
              {this.state.radio ? this.state.radio == "upload" ? <div className='logoDivInner'>
                <LazyImage src={teamLogo ? teamLogo : ImageUrl +'/images/defaultTeamUpload.svg'} />
                {teamLogo ? <button onClick={() => this.setState({ teamLogo: null,default_icon:1,defaultLogo:"images/PeoplePage/teams-logo.svg" })}>Remove</button> :
                  <InputImage className="edit-profile-pic " imageReload={1}>
                    <i className="addImg">{("Upload")}</i>
                    <input
                      id="profle-file"
                      type="file"
                      name="user-profile"
                      multiple={false}
                      accept=".jpeg, .png, .jpg"
                      onChange={(e) => this.onChangeImg(e)}
                      onClick={(e) => e.target.files[0] && this.onChangeImg(e)}
                    />
                  </InputImage>
                }
              </div> : <div className='selectedDiv'>
                {teamIcons?.length > 0 ? teamIcons?.map((img, index) => <SelectImage key={index} onClick={() => this.setState({ teamLogo: this.state.teamLogo == `${ImageUrl}/${img.icon}` ? null : `${ImageUrl}/${img.icon}`, defaultLogo: img.icon })}>
                  <img src={img.icon ? `${ImageUrl}/${img.icon}` : '/public/images/grid.png'} style={{ border: `${ImageUrl}/${img.icon}` == teamLogo && " 2px solid #69C2FF" }} ></img>
                  {`${ImageUrl}/${img.icon}` == teamLogo && <img className='tick' src="/public/images/AdminTeam/Check.png" />}
                </SelectImage>) : null}
              </div> : null}
            </div>
            <TeamCardV2>
              <div className='headingDiv'> <span className='number'>{teamUsers.length}&nbsp;</span>Team Members&nbsp;
                {/* <span className='max'>(Max {maxTeamMemeberCount})</span> */}
              </div>
              {teamDetailsData && teamDetailsData.length ?
                <div className="teamDetails">
                  {teamUsers.length > 0 ? teamUsers.map((member, index) => <MemberDataV2 key={index} captain={member.is_captain === 1} border={index === 0}>
                    <div className='imageDiv'> {member.is_captain === 1 && <CaptainLogo src='/public/images/captain-band.png' />}
                      <LazyImage onError={this.fallBack} src={`${ImageUrl}/${member.profile_image}`} alt="people_icon" /> </div>
                    <div className='textBox'><h1>{member.fullname} {member.is_captain === 1 && <span className='max'> &nbsp;(Captain)</span>} </h1>
                      {/* <h2>{member.department}</h2> */}
                      {member.is_champion && <div className='wellnessChampion'>
                        <img src={"/public/images/AdminChallenges/Subtract.png"} />
                        <span>Wellness Champion</span>
                      </div>}
                    </div>
                    <div className='menueButton'>
                      <img alt='image' src="/public/images/menu-dots.png" />
                      <CustomDropdownMenue id="dropdown-recurring">
                        <CustomDropdownMenuItem eventKey={1} onClick={() => this.handleMakeCaptain(member.uid)} >
                          {member.is_captain ? "Remove Captain" : " Make Captain"}
                        </CustomDropdownMenuItem>
                        <CustomDropdownMenuItem eventKey={2} onClick={() => this.handleRemove(member.uid)}>
                          Remove
                        </CustomDropdownMenuItem>
                      </CustomDropdownMenue>
                    </div>
                  </MemberDataV2>) : null}
                  {<AddMemberButton onClick={() => this.handleAddModal()} >+ Add Member</AddMemberButton>}
                </div> : null}
            </TeamCardV2>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <UpdateTeamButton disabled={teamName.length === 0} onClick={() => this.submitTeamDetails()} >Save The Update</UpdateTeamButton>
        </Modal.Footer>
      </TeamDetailsStyledModal>
    )
  }
}

TeamDetailsPopup.propTypes = {
  history: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  fetchAddOrUpdateTeamDetails: PropTypes.func,
  teamDetailsData: PropTypes.array,
  teamIcons: PropTypes.array,
  showAddModal: PropTypes.func,
  defaultTeamName: PropTypes.string,
  peopleTeams: PropTypes.bool,
  companyId: PropTypes.number,
  pageNo: PropTypes.number,
  limit: PropTypes.number,
  fetchUsersToAddToTeam: PropTypes.func,
  maxTeamMemeberCount: PropTypes.number
};

const mapDispatchToProps = (dispatch) => ({
  fetchAddOrUpdateTeamDetails: (object, companyId, pageNo, limit, teamId) => dispatch(fetchAddOrUpdateTeamDetails(object, companyId, pageNo, limit, teamId)),
  fetchUsersToAddToTeam: (object) => dispatch(fetchUsersToAddToTeam(object))
});

export default connect(null, mapDispatchToProps)(TeamDetailsPopup);
